<template>
  <div class="container-fluid padding-top-layout" v-click-outside="updateActionPosition" @click="updateActionPosition">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title">{{ $t('b_list_product.title_list_products') }}</h1>
          </div>
        </div>
        <div class="wrap__status">
          <div class="wrap__status__item register-btn" @click="clickRegisterProduct()">
            <div
              class="header-custom-select"
              :class="{ 'disable-button': !isRedirectDuration, 'focus-button': showRegisterPulldown }"
            >
              <img class="header-custom-select--icon" src="@/assets/icons/addregistericon.svg" alt="" />
              <div class="header-custom-select--label">{{ $t('b_list_product.pulldown_sign_up') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="category-table main-table custom-table new-register view-table table-list-product"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="false"
          :isEmptySource="isEmptySource"
          :labelEmpty="labelEmpty"
          :isResizeCustome="false"
          @changeFullScreen="onChangeFullScreen"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
          class="table-product-list"
        />
      </div>
      <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
      <history-popup :dialog="historyPopup" :historyLogList="historyLogList" @close="closePopup" />
    </div>
    <div ref="scrollElement">
      <div class="return__button-responsive" :class="{ 'fixed-button-bottom': isFixedButton }">
        <div class="item-responsive register-btn" @click="clickRegisterProduct()">
          <div
            class="header-custom-select"
            :class="{ 'disable-button': !isRedirectDuration, 'focus-button': showRegisterPulldown }"
          >
            <div class="header-custom-select_warp">
              <img class="header-custom-select--icon" src="@/assets/icons/addregistericon.svg" alt="" />
              <div class="header-custom-select--label">{{ $t('b_list_product.pulldown_sign_up') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notification-popup
      :dialog="dialogNotification"
      :message="messageNotification"
      @submit="dialogNotification = false"
    />
    <notification-popup
      :dialog="showPopupDurationSuccess"
      :message="message"
      @submit="showPopupDurationSuccess = false"
    />
  </div>
</template>
    
  <script>
import { mapActions, mapState } from 'vuex';
import { CollectionView } from '@mescius/wijmo';
import DataTable from '@/components/category/data-table';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import * as wjGrid from '@mescius/wijmo.grid';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import CommonButton from '@/components/utils/button.vue';
import HistoryPopup from '@/components/dialogs/history-popup.vue';
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { getStatusNameById, getColorStatusById, getColorTextStatusById } from '@/utils/status';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { REGISTER_PULLDOWN, LIST_PULLDOWN } from '@/constants/registerData';
import { formatValue, $_helper_isNumberType, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import QuestionPopup from '@/components/dialogs/question-popup';
import { addThoundSandComma, addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { getProductList } from '@/api/product/emissionsProduct';
import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
import { getWidthOfDetailButton, getMinWidthOrganization } from '@/utils/multiLanguage'
import { getListDetailBranch } from '@/api/registerData';
export default {
  name: 'ListTemplates',
  data() {
    return {
      items: [
        {
          text: this.$t('b_list_product.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
        {
          text: this.$t('b_list_product.title_list_products'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCTS_LIST_EMISSIONS}`,
        },
      ],
      message: this.$t('b_list_product.message_change_success'),
      duration: '',
      flex: null,
      listProductData: [],
      listData: null,
      durationId: null,
      commentPopup: false,
      historyPopup: false,
      gridColumns: [],
      showTooltip: false,
      historyLogList: [],
      commentHistoryList: [],
      dialogNotification: false,
      messageNotification: this.$t('b_list_all.message_notification'),
      isRedirectDuration: true,
      showPopupDurationSuccess: false,
      showRegisterPulldown: false,
      listLayer: [],
      layer_index: {},
      processClick: {
        isShowAction: false,
      },
      isShowMethodSelect: false,
      indexId: '',
      durationList: [],
      selectedFilterColumn: null,
      isEmptySource: true,
      labelEmpty: this.$t('b_list_product.label_empty'),
      startMonth: null,
      isFixedButton: true,
    };
  },
  components: {
    DataTable,
    CommonButton,
    CommentLogPopup,
    HistoryPopup,
    NotificationPopup,
    QuestionPopup,
  },
  async mounted() {
    await this.updateBreadCrumb(this.items);
    this.actionUpdateIsLoadingTable(true);
    try {
      await this.getDetailBranch()
      await this.getProductsListData();
      this.actionUpdateIsLoadingTable(false);
    } catch (error) {
      this.actionUpdateIsLoadingTable(false);
    }
    this.showPopupDurationSuccess = this.isChangedDurationSuccess;
    this.actionUpdateIsChangedDurationSuccess(false);
    document.addEventListener('scroll', this.updateActionPosition);
  },
  computed: {
    ...mapState('commonApp', ['loadMore', 'isFilter', 'isExpand']),
    ...mapState('registerData', ['isFullScreen', 'isChangedDurationSuccess']),
    ...mapState('userData', ['currentUser']),
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    registerPulldown() {
      return Object.values(REGISTER_PULLDOWN);
    },
    listPullDown() {
      return LIST_PULLDOWN.slice(0, 2);
    },
    listPullDownSp() {
      return LIST_PULLDOWN.slice(0, 2);
    },
    isShowAction() {
      return this.processClick?.isShowAction;
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('registerData', ['actionUpdateIsChangedDurationSuccess', 'actionCheckIvalueDurationSuccess']),
    ...mapActions('newRegisterData', ['updateIsApproved']),

    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;
      // flexgrid.columnHeaders.rows.defaultSize = 68;
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      flexgrid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });

      flexgrid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding === 'id') {
          const item = s.rows[e.row]._data;
          // hide button when status not draft || !owner
          const isHideButtonDelete = item.checkStatus !== 0 || typeof item.is_owner !== 'number' || item.is_owner !== 1;
          if (isHideButtonDelete) {
            e.cell.innerHTML = '';
          }
        }
      });
      this.flex.onSelectionChanged(null);
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    formatValueNumber(number) {
      return addThoundSandComma(number);
    },
    formatValueNumberFullData(number) {
      if (!number) return '';
      if (number === '0') return '0.00';
      return addThoundSandCommaWithTwoDigit(formatBigNumber(number, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    async getProductsListData() {
      const payload = {
        contractor_id: this.currentUser?.user?.contractor_id,
      };
      this.listProductData = [];
      getProductList(payload).then((res) => {
        if (res.data.length <= 5) {
          this.isFixedButton = false;
        }
        res.data.forEach((item) => {
          const branchData = item?.branch_id ? item?.branch : item;
          this.listProductData.push({
            id: item.id,
            products_name: item.name,
            status: item.status === null ? '' : getStatusNameById(item.status),
            colorStatus: item.status === null ? '' : getColorTextStatusById(item.status),
            last_modified: formatDateTimeDataTable(item.updated_at),
            declaration_emission_per_unit: this.formatValueNumberFullData(item.declare_emissions),
            product_emissions: this.formatValueNumberFullData(item.product_emissions),
            code_product: item.code,
            lot_number: item.lot_number,
            start_date: formatDateTimeDataTable(item.start_date, true),
            end_date: formatDateTimeDataTable(item.end_date, true),
            organizational_division:
              branchData?.organizational_division !== null
                ? ORGANIZATIONAL_DATA[branchData?.organizational_division]
                : null,
            company_name: branchData?.company_name,
            business_name: branchData?.business_name || null,
            country: branchData?.country || null,
            layer_3: branchData?.layer_3 || null,
            layer_4: branchData?.layer_4 || null,
            layer_5: branchData?.layer_5 || null,
            layer_6: branchData?.layer_6 || null,
          });
        });
        // this.listLayer = res.layer;
        this.defineTable();
      });
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      const formatItems = ['product_emissions', 'emission_per_product'];
      this.listData = new CollectionView([...this.listProductData], {
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        },
      });
      this.flex.columnGroups = this.getGridColumns();
      this.listData.currentItem = null;
      this.flex.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }
      });
      setMinMaxSizeColumns(this.flex, this.listProductData);
      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource = this.listData?.items?.length <= 0;
    },
    getGridColumns() {
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      }
      const layers = this.listLayer.map((item) => {
        return {
          header: item.layer_name,
          visible: true,
          binding: layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: getWidthByTextContent(item.layer_name) + 10,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          isReadOnly  : true,
          multiLine   : true,
        };
      });

      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'data_id',
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'btn-db table-id hide-filter',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_list_product.button_edit'),
            click: (event, context) => this.onIdClicking(context),
          }),
        },
        {
          header: this.$t('b_list_product.table_status'),
          binding: 'status',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: 'history-btn',
          multiLine: true,
          wordWrap: true,
          cellTemplate: (ctx) => `<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`,
        },
        {
          header: this.$t('b_list_product.table_products_name'),
          binding: 'products_name',
          minWidth: 157,
          width: getWidthByTextContent(this.$t('b_list_product.table_products_name')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_code_product'),
          binding: 'code_product',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_code_product')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_lot_number'),
          binding: 'lot_number',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_lot_number')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_start_date'),
          binding: 'start_date',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_start_date')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_end_date'),
          binding: 'end_date',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_end_date')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_organizational_information'),
          align: 'center',
          collapseTo: 'organizational_division',
          columns: [
            {
              header: this.$t('b_list_product.table_organizational_division'),
              binding: 'organizational_division',
              minWidth: getMinWidthOrganization(),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              multiLine: true,
              wordWrap: true,
            },
            {
              header: this.$t('b_list_product.table_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextContent(this.$t('b_list_product.table_company_name')) + 5,
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true,
            },
            ...layers,
          ],
        },
        {
          header: this.$t('b_list_product.table_emission_by_product'),
          binding: 'product_emissions',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_emission_by_product')),
          maxWidth: 980,
          align: 'right',
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_emission_by_product_declare'),
          binding: 'declaration_emission_per_unit',
          minWidth: getWidthByTextContent(this.$t('b_list_product.table_emission_by_product_declare')),
          maxWidth: 980,
          align: 'right',
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_product.table_last_registration_date'),
          binding: 'last_modified',
          minWidth: 148,
          width: '*',
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();
      }
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      this.$router.push({ path: `/product-emission/product-detail/${rowData.item.id}?type=detail` });
    },
    showRegisterPulldownEvent(event) {
      event.stopPropagation();
      if (this.isRedirectDuration === true) {
        this.isShowMethodSelect = !this.isShowMethodSelect;
        this.showRegisterPulldown = !this.showRegisterPulldown;
      }
      this.setPositionPullDown();
    },
    redirectHandler(item) {
      this.showRegisterPulldown = false;
      switch (item) {
        case REGISTER_PULLDOWN.CREATE_NEW:
          this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCTS_NEW_REGISTER }).catch(() => {});
          break;
        case REGISTER_PULLDOWN.CREATE_FROM_TEMPLATE:
          this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCTS_LIST_TEMPLATES }).catch(() => {});
          break;
        default:
          this.$router.push({ path: '/products/' + ROUTES.LIST_MENU_EMISSION + '/?csv=true' }).catch(() => {});
          break;
      }
    },
    updateActionPosition() {
      this.processClick.isShowAction = false;
      this.showRegisterPulldown = false;
      this.isShowMethodSelect = false;
      this.handleScrollBtnRegister();
    },
    handleScrollBtnRegister() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      if (scrollPosition > scrollElementTop + 239) {
        //position btn currently
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
      this.setPositionPullDown();
    },
    setPositionPullDown() {
      const dropdown = document.querySelector('.item-responsive');
      const rect = dropdown.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      this.$nextTick(() => {
        const dropdownMenu = document.querySelector('.item-responsive .register-pulldown');
        if (dropdownMenu) {
          if (spaceBelow <= dropdownMenu.clientHeight) {
            dropdownMenu.style.top = 'auto';
            dropdownMenu.style.bottom = '100%';
          } else {
            dropdownMenu.style.top = '100%';
            dropdownMenu.style.bottom = 'auto';
          }
        }
      });
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    clickRegisterProduct() {
      this.$router
        .push({ path: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.REGISTER_PRODUCT}`, query: this.$route?.query })
        .catch(() => {});
    },
    async getDetailBranch() {
      const response = await getListDetailBranch()
      this.listLayer = response.layer;
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.updateActionPosition);
  },
};
</script>
<style scoped lang="scss">
.pull-down-action {
  position: fixed;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 89px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  cursor: pointer;
  z-index: 9999;
  &.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 16px 9px;
    gap: 8px;
    min-width: 89px;
    height: 40px;
    &__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    &__icon {
      display: block;
      &.active {
        display: none;
      }
    }

    &:hover {
      background: $goldLight;
      .pull-down-action-item__label {
        color: $monoOffWhite;
      }
      .pull-down-action-item__icon {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
}
.table-list-product {
  background: $bgCusLight;
}
.wrap {
  width: 100%;
  height: 100%;

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 40px;
    .header-custom-select {
      width: 118px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: fit-content;
      min-width: 118px;
      height: 40px;
      background: $monoOffWhite;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
      border: 2px solid transparent;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      &.disable-button {
        cursor: default;
        background-color: $monoLight;
        .header-custom-select--label {
          color: $monoMid;
        }
        .header-custom-select--pulldown {
          .btn-icon {
            display: none;
          }
          .btn-icon_hover {
            display: block;
          }
        }
      }
      transition: 0.1s ease-out;
      &.focus-button {
        border: 2px solid $blueMid;
      }
      &:hover:not(.disable-button) {
        background-color: $monoWhite;
        box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48),
          0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31),
          0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24),
          0px 4px 14px 0px rgba(160, 181, 186, 0.17);
        .header-custom-select--pulldown {
          .btn-icon {
            display: none;
          }
          .btn-icon_hover {
            display: block;
          }
        }
      }
      .btn-icon {
        display: block;
      }
      .btn-icon_hover {
        display: none;
      }
      &--label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 8px;
        min-width: max-content;
      }
      &--pulldown {
        margin-left: 14px;
        width: 10px;
        height: 10px;
        &.open-pulldown {
          transform: rotate(180deg);
        }
        .btn-icon {
          display: block;
        }
        .btn-icon_hover {
          display: none;
        }
        &:hover {
          .btn-icon {
            display: none;
          }
          .btn-icon_hover {
            display: block;
          }
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: flex;
      align-items: center;

      &__wrap {
        margin-left: 12px;
        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    padding: 0 0 40px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      @media (max-width: 768px) {
        padding-bottom: 58px;
      }

      &__content {
        flex-direction: row;
      }

      span,
      .wrap-title {
        display: inline;
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }
  }

  // tooltip icon
  .helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    img {
      z-index: 1;
    }
    &.tooltip {
      display: block;
      justify-content: left;
      background: $monoWhite;
      border-radius: 4px;
      color: $monoBlack;
      margin: 0 auto;
      font-size: 15px;
      padding: 10px;
      height: unset;
      width: 315px;
      max-width: max-content;
      position: absolute;
      right: unset;
      left: 0px;
      bottom: unset;
      z-index: 9999;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);

      position: fixed;
      width: 100%;
    }
  }
}
@include desktop {
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .helper-icon {
    &.tooltip {
      position: absolute;
      width: 400px;
      max-width: unset;
    }
  }
}

.wj-flexgrid .wj-cell-maker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $goldLight;
  // font-family: 'Source Han Sans JP';
}

.wj-cell {
  .wj-cell-maker {
    background: none;
    justify-content: start;
  }

  .wj-cell-maker:hover {
    background: none;
    color: $monoBlack;
  }
}

.duration-wrapper {
  .duration-text {
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  .calculation-page {
    display: flex;
    align-items: center;
    background-color: $bgLight;
    padding: 15px;
    border-radius: 4px;
    height: 56px;

    div {
      margin-right: 20px;

      .duration-content {
        color: $monoBlack;
        font-weight: 700;
        font-size: 11px;
        line-height: 26px;
        letter-spacing: 0.03em;
        padding: 5px 0px 0px 0px;
      }

      .duration-text {
        color: $goldMid;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.03em;
      }
    }
  }
}

.hide-filter {
  .wj-btn {
    display: none;
  }
}
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.wrap {
  width: 100%;
  height: 100%;

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: none;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .duration-wrapper {
      width: 100%;

      .duration {
        .calculation-page {
          justify-content: space-between;
        }
      }
    }
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      padding-bottom: 58px;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      span {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }

    .wrap__status__item {
      span {
        margin-left: 10px;
      }
    }
  }
}
.return__button-responsive.fixed-button-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.return__button-responsive.fixed-button-bottom {
  .item-responsive {
    width: 90%;
  }
}
.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding: 16px 20px;
    position: relative;
    bottom: -80px;
    margin-left: -20px;
    .item-responsive {
      width: 100%;
      margin: 0 auto;
      .header-custom-select {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 7px 15px 9px 18px;
        width: 100%;
        height: 40px;
        background: $monoOffWhite;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        border: 2px solid transparent;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        &:hover:not(.disable-button) {
          background-color: $monoWhite;
          .header-custom-select--pulldown {
            .btn-icon {
              display: none;
            }
            .btn-icon_hover {
              display: block;
            }
          }
        }
        .header-custom-select_warp {
          display: flex;
          .btn-icon {
            display: block;
          }
          .btn-icon_hover {
            display: none;
          }
          .header-custom-select--label {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: $monoBlack;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 10px;
            padding-right: 6px;
            min-width: max-content;
          }
        }
        .header-custom-select--pulldown {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px;
          width: 30px;
          height: 28px;
          &.open-pulldown {
            transform: rotate(180deg);
          }
          .btn-icon {
            display: block;
          }
          .btn-icon_hover {
            display: none;
          }
        }
        &.focus-button {
          border: 2px solid $blueMid;
        }
      }
      .register-button {
        &:hover {
          .v-btn__content {
            .addregistericon {
              display: none !important;
            }
            .addregistericon_active {
              display: block !important;
            }
            .addregistericon_disabled {
              display: none;
            }
          }
        }
      }
      .register-pulldown {
        width: 100%;
        .pulldown-item {
          width: 100%;
        }
      }
    }
  }
}

.register-btn {
  position: relative;
  .register-pulldown {
    position: absolute;
    width: 230px;
    max-height: 160px;
    background: $monoOffWhite;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    z-index: 99;
    top: 100%;
    margin-bottom: 2px;
    .item-pulldown {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 7px 0px 9px 16px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      color: $monoBlack;
      border-bottom: 0;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-left: unset !important;
      &:hover {
        background: $bgLight;
      }
    }
    .pulldown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      width: 230px;
      height: 40px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      &:last-child {
        border-bottom: unset;
      }
      &:hover {
        background: $bgLight;
      }
      .pulldown-text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        white-space: nowrap;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: $monoLight !important;
        .pulldown-text {
          color: $monoMid;
          font-weight: 500;
        }
      }
      &:first-child {
        &:hover {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        &.disabled {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      &:last-child {
        &:hover {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &.disabled {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
@include desktop {
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }

    &__status {
      padding-right: 40px;

      &__item {
        display: block;
      }
    }

    &__block {
      flex-direction: row;

      &__header {
        padding-bottom: 0;
      }
    }

    .wrap__block__header__content {
      display: flex;

      span {
        margin: 8px 10px 0px 0px;
      }

      // .helper-icon {
      //   margin: 16px 10px 0px 0px;
      // }
    }
    .wrap__status__item {
      .addregistericon_active {
        display: none;
      }
      .addregistericon_disabled {
        display: none;
      }
      .register-button {
        &:hover {
          .v-btn__content {
            .addregistericon {
              display: none;
            }
            .addregistericon_active {
              display: block;
            }
            .addregistericon_disabled {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .return__button-responsive {
    display: none;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}

//custome style
@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .wrap {
    &__block {
      flex-direction: column;

      &__header {
        padding-bottom: 58px;
      }
    }

    //&__status {
    //  padding-right: 0;
    //}
  }
}
</style>
<style lang="scss">
.table-product-list {
  .wj-elem-collapse {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 8px;
    top: 6px;
    background: rgba(121, 134, 134, 0.12);
    border-radius: 6px;
  }
  .wj-elem-collapse {
    &.wj-glyph-minus {
      color: $monoDark;
      border-top: 1px solid;
    }
  }
  .line-height_text {
    .wj-cell-maker {
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      text-align: center;
      color: $monoBlack;
    }
  }
  .wj-flexgrid {
    .wj-cell {
      &.disable-btn {
        .wj-cell-maker {
          padding: 0 !important;
          overflow: hidden;
          line-height: 16px;
          color: $monoBlack;
          .data-table-action {
            cursor: text !important;
            background-color: #c7d0d0 !important;
            width: 100%;
            height: 100%;
            &:hover {
              color: $monoBlack !important;
            }
          }
          &:hover {
            color: $monoWhite !important;
          }
        }
      }
    }
  }
}
</style>
  